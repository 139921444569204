<template>
    <KCourse loader-class="MBcont" course-id="2" title="Анатомия тела" :items="items">
        <div class="MBtextcont">
            <p>Как много в этой маленькой части тела странного и непривычного… На первый взгляд!</p>
            <p>Но это нас не остановит. Вас ждет очень много мелкой и медитативной работы. Учитесь чувствовать формы и объемы, совершенствуйте свои навыки в работе с пластиком и с мелкими деталями. Главное не торопитесь, если вам что-то не нравится дайте себе и работе немного отдохнуть, а детали высохнуть.</p>
            <p>Может позже, смотря на дело рук своих, вы поймете, что все не так плохо, как казалось, и достаточно внести пару изменений, чтобы нащупать нужный изгиб…</p>
            <p>Мы с вами так много проделали, что вы уже должны обладать определенными навыками в работе с пластиком. Доверяйте своему внутреннему процессу и чаще смотрите на оригинал! Я в вас верю, у нас все получится ))</p>
            <p class='nomargin'><b> На этом занятии нам понадобятся:</b></p>
            <ol>
                <li>Пластик La Doll</li>
                <li>Кисть синтетическая, плоская</li>
                <li>Стеки</li>
                <li>Карандаш</li>
                <li>Чертеж</li>
                <li>Надфиля, шкурка разной зернистости</li>
                <li>Макетный нож</li>
                <li>Следок</li>
                <li>Вода</li>
            </ol>   
        </div>

        <VideoView video-id="f137d248f5aa4957876733e15c44454c"/>

        <div class="MBtextcont">
            <p class='nomargin'><b>Задание:</b></p>
            <p>Проработать кисть и отшкурить. Придать ей окончательный вид.</p>
        </div>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
        return {
            items: [
                { title: "Готовим трафареты", url:"/mycourses/body/1"},
                { title: "Заготовки из пластилина", url:"/mycourses/body/2"},
                { title: "Подготовка к обтяжке", url:"/mycourses/body/3"},
                { title: "Обтяжка пластиком", url:"/mycourses/body/4"},
                { title: "Достаем пластилин", url:"/mycourses/body/5"},
                { title: "Основа кисти", url:"/mycourses/body/6"},
                { title: "Основа стопы", url:"/mycourses/body/7"},
                { title: "Анатомия руки", url:"/mycourses/body/8"},
                { title: "Анатомия кисти", url:"/mycourses/body/9"},
                { title: "Анатомия ноги", url:"/mycourses/body/10"},
                { title: "Анатомия стоп", url:"/mycourses/body/11"},
                { title: "Анатомия тела", url:"/mycourses/body/12"},
            ],
        }
        }
    }
</script>